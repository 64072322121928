<template>
 <sdDrawer
  ref="ScheduledTaskAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Systeemtaak aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateScheduledTask">
    <a-row :gutter="16">
     <a-col :sm="12" :xs="12" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Taaktype"
                   name="type">
       <a-select v-model:value="form.type"
                 :allowClear="true" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in typeOptions" :key="index"
                         :value="item.value" name="type">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="12" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="App"
                   name="appType">
       <a-select v-model:value="form.appType"
                 :allowClear="true" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in appTypeOptions" :key="index"
                         :value="item.value" name="appType">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
     <a-button class="btn-signin" html-type="submit" size="large" type="primary"> Opslaan</a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";

const ScheduledTaskAdd = defineComponent({
 name: 'ScheduledTaskAdd',
 components: {
  FormValidationWrap,
 },
 setup() {
  const ScheduledTaskAddRef = ref()
  const formRef = ref();
  const {dispatch} = useStore();
  const form = reactive({
   name: null,
  });
  const rules = {
   name: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const appTypeOptions = [
   {
    value: '2SOLAR',
    key: '2Solar',
   },
  ]

  const typeOptions = [
   {
    value: 'SENDORDERS',
    key: 'Orderverzending',
   },
   {
    value: 'SYNCTARIFF',
    key: 'Tarief synchronisatie',
   },
   {
    value: 'SYNCSTATUSSES',
    key: 'Status synchronisatie',
   },
   {
    value: 'SENDFILES',
    key: 'Orderbestand verzending',
   }
  ]
  const closeDrawer = () => {
   ScheduledTaskAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getScheduledTasks')
  }
  const CreateScheduledTask = () => {
   dispatch('createScheduledTask', {value: form, close: closeDrawer});
  };
  return {
   form,
   rules,
   CreateScheduledTask,
   ScheduledTaskAddRef,
   formRef,
   appTypeOptions,
   typeOptions,
  };
 },
});

export default ScheduledTaskAdd;
</script>
